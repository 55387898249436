import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import BusinessInfo from './BusinessInfo';

const Footer = () => {
    const location = useLocation();
    const params = useParams();
    // console.log(params);
    // `/times/${params.serviceId}`
    return (
        <div>
            {
                location.pathname !== "/login" && <div className={`${location.pathname === `/` ? "fixed" : location.pathname === `/times` ? "fixed" : ""} bottom-0 w-full`}>
                    <div className='bg-red-600 text-white p-5 text-center text-xs'>
                        <p>©Copyright {BusinessInfo.name} 2023</p>
                    </div>
                    {/* {
                    location.pathname === '/' ? <div className='bg-red-600 text-white p-5 text-center'>
                        <p>©Copyright Sawda Enterprise 2023</p>
                    </div>: location.pathname === '/times'? <div className='bg-red-600 text-white p-5 mt-48 text-center'>
                        <p>©Copyright Sawda Enterprise 2023</p>
                    </div> :''
                } */}
                </div>
            }
        </div>
    );
};

export default Footer;