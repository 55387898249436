import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import profitimg from '../../../../src/assets/images/profit.png'
import useFetchData from '../../../hooks/useFetchData';

const ThisMonthProfit = () => {
    const { items: profit } = useFetchData("https://servers.clearsoftwares.net/demo/daywiseprofit");
    const TotalProfit = profit?.reduce((total, currentValue) => total + currentValue?.profit, 0);


    return (
        <div class="mx-auto w-11/12 bg-coral text-black rounded-lg overflow-hidden bg-[#ECECFF] shadow-lg glassmorphism p-5">
            <div className='flex justify-between items-center'>
                <div class="flex flex-col justify-center items-center">
                    <div class="text-sm font-semibold mb-2">This Month Profit</div>
                    <p class="text-xl text-black">৳ {(TotalProfit).toFixed(2)}</p>
                </div>
                <div class="">
                    <img src={profitimg} alt="Card image" class="mx-auto my-auto h-20 w-20 rounded-full border-4 border-red-600 object-cover" />
                </div>
            </div>
        </div>

    );
};

export default ThisMonthProfit;
