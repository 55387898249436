// import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import useCurrentStock from '../../../../hooks/useCurrentStock';
import { useRef } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import beep from '../../../../assets/audio/beep.mp3';
import SupplierList from '../../../../hooks/SupplierList';
import Loader from '../../../Shared/Loader';
import useFetchData from '../../../../hooks/useFetchData';
import useCurrentStockForPurchase from '../../../../hooks/useCurrentStockForPurchase';

const UpdatePosPurchase = () => {
    const { items } = useFetchData('https://servers.clearsoftwares.net/demo/getproductscode');
    const { posSupplier } = useSelector((state) => state.purchaseInvoice);
    const userAc = useSelector((state) => state.user.user);
    const { products, _id, purchaseInvoice, purchaseDate, supplierCode, purchaseSupplier,
        purchaseAddress, purchasePhone, purchaseOpening, fixedDis, roundingDis, totalAmount, grandTotal,
        payment: updatePayment, dueAmount
    } = useSelector((state) => state.purchaseInvoice.updatePurchase);
    const { isSearching, setIsSearching, searchTerm, handleSearchTermChange, filtered, isLoading } = useCurrentStockForPurchase();
    // const { stockData: productList } = useCurrentStock();
    // const [searchResult, setSearchResult] = useState([]);
    // const [isSearching, setIsSearching] = useState(false);
    const [searchText, setSearchText] = useState();
    const [pdCode, setPdCode] = useState('')
    const navigate = useNavigate();
    const [purchaseCount, setPurchaseCount] = useState();
    const [loading, setLoading] = useState(true);
    // const invoiceNo = `INV-${(purchaseCount + 1).toString().padStart(6, '0')}`;
    const invoiceNo = `INV-${(purchaseCount?.result + 1).toString().padStart(6, '0')}`;



    ///////////
    const [productList, setProductList] = useState([]);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [units, setUnits] = useState([]);
    const [origins, setOrigins] = useState([]);
    const [isAddingProduct, setIsAddingProduct] = useState(false);
    const [isUpdatingProduct, setIsUpdatingProduct] = useState(false);
    const [updatingProduct, setUpdatingProduct] = useState();
    const [newProduct, setNewProduct] = useState({
        code: '',
        name: '',
        category: '',
        brand: '',
        origin: '',
        unit: '',
        purchase: '',
        sell: '',
        minimum: '',
        opening: '',
        remarks: '',
        status: 'Active',
        user: userAc?.email.split('@')[0],
        approvedBy: false,
    });

    const isExist = items?.filter(pcode => pcode?.code === pdCode);
    const duplicate = isExist?.length ? true : false;

    let barcode = productList?.length + 1;
    const getbarcode = `2023${barcode}`;
    const productCodeRef = useRef('');

    const codeRef = useRef();
    const nameRef = useRef();
    const categoryRef = useRef();
    const unitRef = useRef();
    const purchaseRef = useRef();
    const sellRef = useRef();
    const minimumRef = useRef();
    const openingRef = useRef();
    const remarksRef = useRef();

    // Function to handle adding a new product
    const handleAddProduct = () => {
        const productCode = productCodeRef.current.value;
        const opening = parseFloat(newProduct.opening);
        const minimum = parseFloat(newProduct.minimum);
        // Validate the input fields (you can add more validation as needed)
        if (!productCode || !newProduct.name || !newProduct.purchase || !newProduct.sell
            || !newProduct.category || !newProduct.unit
            || !newProduct.minimum || !newProduct.opening) {
            toast.error('Please fill in all product details.');
            return;
        };


        fetch('https://servers.clearsoftwares.net/demo/products', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ ...newProduct, code: productCode, opening: opening, minimum: minimum }),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Product Added')
                setDataArray(prevDataArray => [...prevDataArray, {
                    ...newProduct, _id: data.insertedId,
                    opening: parseFloat(newProduct.opening), minimum: parseFloat(newProduct.minimum),
                    rate: newProduct.purchase, total: 1 * newProduct.purchase, quantity: 1, code: productCode
                }]);
                barcode = barcode + 1;
            });

        // Add the new product to the dataArray



        // Clear the input fields and reset state
        setNewProduct({
            code: '',
            name: '',
            category: '',
            brand: '',
            origin: '',
            unit: '',
            purchase: '',
            sell: '',
            minimum: '',
            opening: '',
            remarks: '',
            status: '',
            user: '',
            approvedBy: '',
        });
        setIsAddingProduct(false);
    };

    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/demo/getpurchasecount')
            .then(res => res.json())
            .then(data => {
                setPurchaseCount(data);
                setLoading(false)
            })
    }, []);


    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/demo/getproducts')
            .then(res => res.json())
            .then(data => {
                setProductList(data);
                setLoading(false)
            })
    }, [productList])
    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/demo/getcategories')
            .then(res => res.json())
            .then(data => {
                setCategories(data);
                setLoading(false)
            })
    }, [productList])
    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/demo/getbrands')
            .then(res => res.json())
            .then(data => {
                setBrands(data);
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/demo/getunits')
            .then(res => res.json())
            .then(data => {
                setUnits(data);
            })
    }, [])
    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/demo/getorigins')
            .then(res => res.json())
            .then(data => {
                setOrigins(data);
            })
    }, [])
    ////////////////



    const [isPayment, setIsPayment] = useState(false);
    const [isDiscount, setIsDiscount] = useState(false);
    const [discountAmount, setDiscountAmount] = useState('');
    const [payment, setPayment] = useState('');
    const [roundingAmount, setRoundingAmount] = useState(0);
    const [suppliers, setSuppliers] = useState([]);



    const totalRef = useRef('');
    const discountRef = useRef();
    const grandTotalRef = useRef();
    const paymentRef = useRef();
    const dueAmountRef = useRef();
    const discountTypeRef = useRef();

    // const totalRef = useRef('');
    const invoiceRef = useRef('');
    const dateRef = useRef('')
    const beepAudioRef = useRef(null);

    const supplierCodeRef = useRef('');
    const supplierRef = useRef('');
    const addressRef = useRef('');
    const phoneRef = useRef('');
    const openingBalanceRef = useRef('');

    const date = new Date();
    const today = format(date, "yyyy-MM-dd");


    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/demo/getsuppliers')
            .then(res => res.json())
            .then(data => {
                setSuppliers(data);
                setLoading(false)
            })
    }, []);


    useEffect(() => {
        if (searchTerm && filtered) {
            const exactMatch = filtered.find(product => product.code === searchTerm);
            if (exactMatch) {
                // Ensure that the component has mounted before calling handleAddProductToInvoice
                handleAddProductToInvoice(exactMatch);
            }
        }

    }, [searchTerm, filtered]);






    // State to store the modified array
    const [dataArray, setDataArray] = useState(products);


    // Function to calculate the default quantity
    const calculateDefaultQuantity = (minimum) => {
        return minimum * 2;
    };

    // Function to calculate the default total
    const calculateDefaultTotal = (defaultQuantity, purchase) => {
        return defaultQuantity * purchase;
    };

    // Set default values for quantity and total when the component mounts
    useEffect(() => {
        setDataArray(prevDataArray => {
            return prevDataArray?.map(item => {
                const defaultQuantity = 1;
                const defaultTotal = calculateDefaultTotal(defaultQuantity, item.purchase);
                // const cost = parseFloat(item.purchase) * parseFloat(item.quantity); // Calculate the cost
                return {
                    ...item,
                    // cost: cost.toFixed(2), // Add the cost property
                    quantity: item.quantity || defaultQuantity,
                    total: item.total || defaultTotal,
                };
            });
        });
    }, []);


    if (loading) {
        return <Loader />
    }

    const handleInputChange = (index, field, value) => {
        setDataArray((prevDataArray) => {
            const newDataArray = [...prevDataArray];
            newDataArray[index] = {
                ...newDataArray[index],
                [field]: value,
            };

            const cost = parseFloat(newDataArray[index].purchase) * parseFloat(value);
            newDataArray[index].cost = cost;

            newDataArray[index].total =
                newDataArray[index].quantity * newDataArray[index].purchase;
            return newDataArray;
        });
    };

    // Function to remove an item from the dataArray
    const handleRemoveItem = index => {
        setDataArray(prevDataArray => {
            const newDataArray = [...prevDataArray];
            newDataArray.splice(index, 1);
            return newDataArray;
        });
    };
    const totalValue = dataArray.reduce((total, item) => total + (item.total || 0), 0);
    // Send the modified data to the server (e.g., on form submission)


    // New Product Functions

    // const handleSearchResult = (event) => {
    //     const searchText = event.target.value.toLowerCase(); // Convert search text to lowercase
    //     const match = productList.filter(product =>
    //         product.name.toLowerCase().includes(searchText) ||
    //         product.code.includes(searchText)
    //     );
    //     setSearchText(searchText); // Update search text state
    //     setSearchResult(match);
    //     setIsSearching(true);
    //     const exactMatch = match.find(product => product.code === searchText);

    //     if (exactMatch) {
    //         // If an exact match is found, trigger the addProductToInvoice function
    //         handleAddProductToInvoice(exactMatch);
    //     }
    // }

    const handleAddProductToInvoice = (product) => {

        // Check if the product already exists in the dataArray
        const existingProductIndex = dataArray.findIndex((item) => item._id === product._id);

        if (existingProductIndex !== -1) {
            // Product already exists, update the quantity and total
            const updatedDataArray = [...dataArray];
            const defaultQuantity = 1;
            updatedDataArray[existingProductIndex].quantity += 1;
            updatedDataArray[existingProductIndex].total = updatedDataArray[existingProductIndex].quantity * updatedDataArray[existingProductIndex].purchase;
            const rate = updatedDataArray[existingProductIndex].purchase;
            updatedDataArray[existingProductIndex].rate = rate;
            setDataArray(updatedDataArray);


            // toast.error('Product Already Added');
            // if (beepErrorRef.current) {
            //     beepErrorRef.current.play();
            // }
        } else {
            // Product doesn't exist, add it to the dataArray
            setDataArray((prevDataArray) => {
                const defaultQuantity = 1; // Set the default quantity to 1
                const defaultTotal = calculateDefaultTotal(defaultQuantity, product.purchase);
                const rate = product.purchase;
                return [...prevDataArray, { ...product, quantity: defaultQuantity, total: defaultTotal, rate: rate }];
            });
        }

        setIsSearching(false);
        setSearchText('');
        setIsDiscount(true);
        if (beepAudioRef.current) {
            beepAudioRef.current.play();
        }
    }

    // New


    const handleDiscount = (event) => {
        const discount = parseFloat(event?.target?.value);
        const type = discountTypeRef.current.value;

        if (type === '%') {
            setDiscountAmount(total / 100 * discount)
        } else {
            setDiscountAmount(discount);
        }



        // const grand = total - discount;
        // setTotalAmount(grand);
        setIsDiscount(true);
    }

    const handleRounding = (event) => {
        const amount = parseFloat(event?.target?.value);
        setRoundingAmount(amount);
        setIsDiscount(true);
    }
    const handlePayment = (event) => {
        const payment = parseInt(event?.target?.value);
        setPayment(payment);
        // const dueAmount = totalAmount - payment;
        // setDueAmount(dueAmount);
        setIsPayment(true);
        setIsDiscount(true);
    }

    const handleSubmitPosPurchase = () => {
        const purchaseInvoice = invoiceRef.current.value;
        const purchaseDate = dateRef.current.value;
        const supplierCode = supplierCodeRef.current.innerText;
        const purchaseSupplier = supplierRef.current.innerText;
        const purchaseAddress = addressRef.current.innerText;
        const purchasePhone = phoneRef.current.innerText;
        const purchaseOpening = openingBalanceRef.current.innerText;
        const totalAmount = parseInt(totalRef.current.innerText);
        const discount = discountAmount + roundingAmount;
        const grandTotal = parseInt(grandTotalRef.current.value);
        const paymentdata = parseInt(paymentRef.current.value);
        const payment = paymentdata ? parseInt(paymentRef.current.value) : 0;
        const dueAmount = parseInt(dueAmountRef.current.value);
        const user = userAc?.email.split('@')[0];
        const month = monthName + " " + yearName;

        const data = {
            products: dataArray, purchaseInvoice, purchaseDate, supplierCode, purchaseSupplier, purchaseAddress,
            purchasePhone, purchaseOpening, totalAmount, discount, fixedDis: discountAmount ? discountAmount : 0,
            roundingDis: roundingAmount ? roundingAmount : 0, grandTotal, payment, user, dueAmount, month
        };

        const url = `https://servers.clearsoftwares.net/demo/getpurchases/${_id}`
        fetch(url, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Purchase Updated Successfull')
                console.log(data);
                navigate("/managepurchase")
                setTimeout(() => { window.location.reload(); }, 2000);

            })

    }

    let currentDate = new Date();

    // Get the current month and year as strings
    let monthName = currentDate.toLocaleString('default', { month: 'long' });
    let yearName = currentDate.toLocaleString('default', { year: 'numeric' });

    const total = dataArray.reduce((total, currentValue) => total + parseFloat(currentValue?.total), 0);


    const handleUpdateProduct = (product, index) => {
        setUpdatingProduct(product);
        handleRemoveItem(index)
        setIsUpdatingProduct(true);
    }
    const handleCancelUpdate = () => {
        setDataArray(prevDataArray => [...prevDataArray, { ...updatingProduct, _id: updatingProduct._id, total: 1 * updatingProduct.purchase }]);
        setIsUpdatingProduct(false);
    }

    const handleUpdateOldProduct = () => {
        const name = nameRef.current.value;
        const code = codeRef.current.value;
        const category = categoryRef.current.value;
        // const brand=brandRef.current.value
        // const origin=originRef.current.value;
        const unit = unitRef.current.value;
        const purchase = parseFloat(purchaseRef.current.value);
        const sell = parseFloat(sellRef.current.value);
        const minimum = parseFloat(minimumRef.current.value);
        const opening = parseFloat(openingRef.current.value);
        const remarks = remarksRef.current.value;
        const user = userAc?.email.split('@')[0]
        const status = 'Active';
        const approvedBy = false;
        const newData = { name, code, category, unit, purchase, sell, minimum, opening, remarks, user, status, approvedBy }

        fetch(`https://servers.clearsoftwares.net/demo/getproducts/${updatingProduct._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => {
                toast('Product Upadated Successful');
                setDataArray(prevDataArray => [...prevDataArray, {
                    ...newData, _id: updatingProduct._id,
                    total: 1 * newData.purchase, rate: newData.purchase, quantity: 1
                }]);
                setIsUpdatingProduct(false);
            })
    };

    const handleProductCode = (event) => {
        const code = event.target.value;
        setPdCode(code)
    }

    return (
        <div className="mx-2 py-4">
            {/* <audio ref={beepAudioRef} src={beep} /> */}
            {/* <audio ref={beepErrorRef} src={error} /> */}
            <h1 className="text-lg font-bold mb-4">Add A Purchase</h1>
            <div className='flex'>
                <SupplierList suppliers={suppliers} />
            </div>
            <div className='flex flex-col lg:flex-row justify-start items-center gap-2'>
                <div className='lg:col-span-4 mt-7'>
                    <table class="table w-full mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Select Date</th>
                                <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            <tr className=''>
                                <td> <input ref={dateRef} type="date" defaultValue={purchaseDate ? purchaseDate : today} placeholder="Date" class="rounded-lg text-center text-xs bg-red-200 p-3 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required /></td>
                                <td><input ref={invoiceRef} value={purchaseInvoice ? purchaseInvoice : invoiceNo} type="text" placeholder="Type Supplier Name Here" class="rounded-lg text-xs text-center bg-red-200 p-3 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required /></td>
                            </tr>
                            {/* {details?.duplicate && <tr className=''>
                                <td></td>
                                <td className='text-red-600 font-bold'>(Duplicate Please Change)</td>
                            </tr>} */}
                        </tbody>
                    </table>
                </div>
                <div className={`lg:col-span-7 overflow-auto w-full lg:w-auto ${posSupplier.length === 0 ? 'lg:mb-0' : 'lg:mb-0'} lg:overflow-visible`}>
                    <table class="table lg:w-1/2 text-xs">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Supplier Code</th>
                                <th className='bg-red-600 text-white normal-case'>Supplier Name</th>
                                <th className='bg-red-600 text-white normal-case'>Supplier Address</th>
                                <th className='bg-red-600 text-white normal-case'>Mobile No</th>
                                <th className='bg-red-600 text-white normal-case'>Previews Due</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            <tr className=''>
                                <td ref={supplierCodeRef} className='h-8'>{posSupplier?.code ? posSupplier?.code : supplierCode}</td>
                                <td ref={supplierRef} className='h-8'>{posSupplier?.name ? posSupplier?.name : purchaseSupplier}</td>
                                <td ref={addressRef}>{posSupplier?.address ? posSupplier?.address : purchaseAddress}</td>
                                <td ref={phoneRef}>{posSupplier?.phone ? posSupplier?.phone : purchasePhone}</td>
                                <td ref={openingBalanceRef}>{posSupplier?.due ? (posSupplier?.due)?.toFixed(2) : purchaseOpening}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='flex gap-2'>
                <div className='flex justify-center lg:justify-start items-center my-4'>
                    <div class="form-control w-60 mx-auto lg:mx-px">
                        <input onChange={handleSearchTermChange} value={searchTerm} type="text" placeholder="Scan Code/Search Product" class="input text-xs input-bordered border-dark-purple w-full max-w-xs" />
                    </div>
                </div>

                <div className="flex justify-center lg:justify-start items-center my-4">
                    <button
                        className="btn btn-sm text-white"
                        onClick={() => setIsAddingProduct(!isAddingProduct)}
                    >
                        {isAddingProduct ? 'Cancel' : 'Add Product'}
                    </button>
                </div>
            </div>


            <hr />
            <div style={isSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isSearching ? 'h-60' : "h-8"} w-80 overflow-auto`}>
                {
                    // isSearching ? searchResult.map(product =>
                    //     <label for="update-modal" onClick={() => handleAddProductToInvoice(product)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 text-xs p-3 text-white te  shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{product?.name}</label>) :
                    filtered?.map(product =>
                        <label for="update-modal" onClick={() => handleAddProductToInvoice(product)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 text-xs p-3 text-white  te  shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{product?.name}</label>)
                }
            </div>


            <div className='overflow-auto lg:col-span-12 text-xs z-30 mb-4'>
                <table class="table w-1/2 mx-auto text-xs">
                    {isAddingProduct && <thead className='text-center'>
                        <tr>
                            <th className='bg-red-600 text-white normal-case'>Product Code</th>
                            <th className='bg-red-600 text-white normal-case w-20'>Product Name</th>
                            {/* <th className='bg-red-600 text-white normal-case'>Minimum Stock</th> */}
                            {/* <th className='bg-red-600 text-white normal-case'>Current Stock</th> */}
                            <th className='bg-red-600 text-white normal-case'>Category</th>
                            <th className='bg-red-600 text-white normal-case'>Unit</th>
                            <th className='bg-red-600 text-white normal-case'>Purchase</th>
                            <th className='bg-red-600 text-white normal-case'>Sale</th>
                            <th className='bg-red-600 text-white normal-case'>Minimum</th>
                            <th className='bg-red-600 text-white normal-case'>Opening</th>
                            <th className='bg-red-600 text-white normal-case'>Remarks</th>
                        </tr>
                    </thead>}
                    <tbody className='text-center'>
                        {isAddingProduct && (
                            <tr className='text-xs'>
                                <td>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Product Code"
                                            defaultValue={getbarcode}
                                            ref={productCodeRef}
                                            onChange={handleProductCode}
                                            className="p-2 border rounded-lg w-24"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Product Name"
                                            value={newProduct.name}
                                            onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
                                            className="p-2 border rounded-lg"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <select value={newProduct.category} onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })} class="p-2 border rounded-lg" required>
                                            <option value='' disabled selected>Category</option>
                                            {
                                                categories.map(category => <option key={category._id} value={category.name}>{category.name}</option>)
                                            }

                                        </select>
                                    </div>
                                </td>
                                {/* <td>
                                        <div>
                                            <select value={newProduct.brand} onChange={(e) => setNewProduct({ ...newProduct, brand: e.target.value })} class="p-2 border rounded-lg" required>
                                                <option value='' selected disabled>Select Brand</option>
                                                {
                                                    brands.map(brand => <option key={brand._id} value={brand.name} >{brand.name}</option>)
                                                }

                                            </select>
                                        </div>
                                    </td> */}
                                {/* <td>
                                        <div>
                                            <select value={newProduct.origin} onChange={(e) => setNewProduct({ ...newProduct, origin: e.target.value })} class="p-2 border rounded-lg" required>
                                                <option value='' selected disabled>Select Origin</option>
                                                {
                                                    origins.map(origin => <option key={origin._id} value={origin.name} >{origin.name}</option>)
                                                }

                                            </select>
                                        </div>
                                    </td> */}
                                <td>
                                    <div>
                                        <select defaultValue={newProduct.unit} onChange={(e) => setNewProduct({ ...newProduct, unit: e.target.value })} class="p-2 border rounded-lg" required>
                                            <option value='' disabled selected>Select Unit</option>
                                            {
                                                units.map(unit => <option key={unit._id} value={unit.type} >{unit.type}</option>)
                                            }

                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input
                                            type="number"
                                            placeholder="Purchase Price"
                                            value={newProduct.purchase}
                                            onChange={(e) => setNewProduct({ ...newProduct, purchase: parseFloat(e.target.value) })}
                                            className="p-2 border rounded-lg w-24"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input
                                            type="number"
                                            placeholder="Sales Price"
                                            value={newProduct.sell}
                                            onChange={(e) => setNewProduct({ ...newProduct, sell: parseFloat(e.target.value) })}
                                            className="p-2 border rounded-lg w-24"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input
                                            type="number"
                                            placeholder="Minimum Stock"
                                            value={newProduct.minimum}
                                            onChange={(e) => setNewProduct({ ...newProduct, minimum: e.target.value })}
                                            className="p-2 border rounded-lg w-16"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input
                                            type="number"
                                            placeholder="Opening Stock"
                                            value={newProduct.opening}
                                            onChange={(e) => setNewProduct({ ...newProduct, opening: e.target.value })}
                                            className="p-2 border rounded-lg w-16"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Remarks"
                                            value={newProduct.remarks}
                                            onChange={(e) => setNewProduct({ ...newProduct, remarks: e.target.value })}
                                            className="p-2 border rounded-lg w-28"
                                        />
                                    </div>
                                </td>
                            </tr>

                        )}
                        {
                            duplicate && (<tr>
                                <td className='text-red-600 font-bold text-xs'>Duplicate</td>
                            </tr>)
                        }

                        {isAddingProduct && (<tr>


                            <td>
                                <div className="col-span-2 text-center">
                                    <button
                                        disabled={duplicate}
                                        className="btn btn-xs btn-primary"
                                        onClick={handleAddProduct}
                                    >
                                        Save Product
                                    </button>
                                </div>
                            </td>
                        </tr>)

                        }

                    </tbody>
                </table>
            </div>
            <div className='overflow-auto lg:col-span-12 text-xs z-30 mb-4'>
                <table class="table w-1/2 mx-auto text-xs">
                    {isUpdatingProduct && <thead className='text-center'>
                        <tr>
                            <th className='bg-red-600 text-white normal-case'>Product Code</th>
                            <th className='bg-red-600 text-white normal-case w-20'>Product Name</th>
                            {/* <th className='bg-red-600 text-white normal-case'>Minimum Stock</th> */}
                            {/* <th className='bg-red-600 text-white normal-case'>Current Stock</th> */}
                            <th className='bg-red-600 text-white normal-case'>Category</th>
                            <th className='bg-red-600 text-white normal-case'>Unit</th>
                            <th className='bg-red-600 text-white normal-case'>Purchase</th>
                            <th className='bg-red-600 text-white normal-case'>Sale</th>
                            <th className='bg-red-600 text-white normal-case'>Minimum</th>
                            <th className='bg-red-600 text-white normal-case'>Opening</th>
                            <th className='bg-red-600 text-white normal-case'>Remarks</th>
                        </tr>
                    </thead>}
                    <tbody className='text-center'>
                        {isUpdatingProduct && (
                            <tr className='text-xs'>
                                <td>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Product Code"
                                            disabled
                                            value={updatingProduct?.code}
                                            ref={codeRef}
                                            className="p-2 border rounded-lg w-24"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Product Name"
                                            defaultValue={updatingProduct?.name}
                                            ref={nameRef}
                                            className="p-2 border rounded-lg"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <select defaultValue={updatingProduct?.category} ref={categoryRef} class="p-2 border rounded-lg" required>
                                            <option value='' disabled selected>Category</option>
                                            {
                                                categories.map(category => <option key={category._id} value={category.name}>{category.name}</option>)
                                            }

                                        </select>
                                    </div>
                                </td>
                                {/* <td>
                                        <div>
                                            <select value={newProduct.brand} onChange={(e) => setNewProduct({ ...newProduct, brand: e.target.value })} class="p-2 border rounded-lg" required>
                                                <option value='' selected disabled>Select Brand</option>
                                                {
                                                    brands.map(brand => <option key={brand._id} value={brand.name} >{brand.name}</option>)
                                                }

                                            </select>
                                        </div>
                                    </td> */}
                                {/* <td>
                                        <div>
                                            <select value={newProduct.origin} onChange={(e) => setNewProduct({ ...newProduct, origin: e.target.value })} class="p-2 border rounded-lg" required>
                                                <option value='' selected disabled>Select Origin</option>
                                                {
                                                    origins.map(origin => <option key={origin._id} value={origin.name} >{origin.name}</option>)
                                                }

                                            </select>
                                        </div>
                                    </td> */}
                                <td>
                                    <div>
                                        <select value={updatingProduct?.unit} ref={unitRef} class="p-2 border rounded-lg" required>
                                            <option value='' disabled selected>Select Unit</option>
                                            {
                                                units.map(unit => <option key={unit._id} value={unit.type} >{unit.type}</option>)
                                            }

                                        </select>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input
                                            type="number"
                                            placeholder="Purchase Price"
                                            defaultValue={updatingProduct?.purchase}
                                            ref={purchaseRef}
                                            className="p-2 border rounded-lg w-24"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input
                                            type="number"
                                            placeholder="Sales Price"
                                            defaultValue={updatingProduct?.sell}
                                            ref={sellRef}
                                            className="p-2 border rounded-lg w-24"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input
                                            type="number"
                                            placeholder="Minimum Stock"
                                            defaultValue={updatingProduct?.minimum}
                                            ref={minimumRef}
                                            className="p-2 border rounded-lg w-16"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input
                                            type="number"
                                            placeholder="Opening Stock"
                                            disabled
                                            value={updatingProduct?.opening}
                                            ref={openingRef}
                                            className="p-2 border rounded-lg w-16"
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <input
                                            type="text"
                                            placeholder="Remarks"
                                            defaultValue={updatingProduct?.remarks}
                                            ref={remarksRef}
                                            className="p-2 border rounded-lg w-28"
                                        />
                                    </div>
                                </td>
                            </tr>

                        )}
                        {isUpdatingProduct && (<tr>


                            <td>
                                <div className="col-span-2 text-center">
                                    <button
                                        className="btn btn-xs btn-primary"
                                        onClick={handleUpdateOldProduct}
                                    >
                                        Update Product
                                    </button>
                                </div>
                            </td>
                            <td>
                                <div className="col-span-2 text-center">
                                    <button
                                        className="btn btn-xs btn-primary"
                                        onClick={handleCancelUpdate}
                                    >
                                        Cancel Update
                                    </button>
                                </div>
                            </td>

                        </tr>)

                        }

                    </tbody>
                </table>
            </div>

            <div className='lg:grid lg:grid-cols-12 gap-2 h-screen'>
                <div className='overflow-auto lg:col-span-10 text-xs z-30 mb-4'>
                    <table class="table w-1/2 mx-auto text-xs">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL#</th>
                                <th className='bg-red-600 text-white normal-case'>Product Code</th>
                                <th className='bg-red-600 text-white normal-case w-20'>Product Name</th>
                                {/* <th className='bg-red-600 text-white normal-case'>Minimum Stock</th> */}
                                {/* <th className='bg-red-600 text-white normal-case'>Current Stock</th> */}
                                <th className='bg-red-600 text-white normal-case'>Purchase Qyt</th>
                                <th className='bg-red-600 text-white normal-case'>Unit</th>
                                <th className='bg-red-600 text-white normal-case'>Sale Rate</th>
                                <th className='bg-red-600 text-white normal-case'>Total</th>
                                <th className='bg-red-600 text-white normal-case'>Action</th>
                                <th className='bg-red-600 text-white normal-case'>Action</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                dataArray?.map((product, index) => <tr key={product._id} className='hover'>
                                    <td className='h-8'>{index + 1}</td>
                                    <td className='h-8'>{product?.code}</td>
                                    <td className='text-xs w-20'>{product?.name}</td>
                                    {/* <td><input type="number" disabled value={product?.minimum} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-20 max-w-lg" /></td> */}
                                    {/* <td><input type="number" disabled value={product?.stock} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-20 max-w-lg" /></td> */}
                                    <td><input
                                        type="number"
                                        className="border border-gray-300 px-2 py-1 rounded-md w-16 text-center"
                                        value={product.quantity || 0}
                                        onChange={e => handleInputChange(index, 'quantity', parseInt(e.target.value))}
                                    /></td>
                                    <td className='h-8 w-8'>{product?.unit}</td>
                                    <td> <input
                                        type="number"
                                        className="border border-gray-300 px-2 py-1 rounded-md w-20 text-center"
                                        value={product.purchase || 0}
                                        onChange={e => handleInputChange(index, 'purchase', parseFloat(e.target.value))}
                                    /></td>
                                    <td><input type="number" value={(product.total)?.toFixed(2) || 0} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-28 max-w-lg" readOnly /></td>
                                    <td> <button
                                        className="bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded-md"
                                        onClick={() => handleRemoveItem(index)}
                                    >
                                        Remove
                                    </button> </td>
                                    {product?._id ? <td> <button
                                        className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded-md"
                                        onClick={() => handleUpdateProduct(product, index)}
                                    >
                                        Update
                                    </button> </td> : <td> <button disabled className='btn btn-warning btn-xs'>New</button></td>}
                                </tr>)
                            }
                        </tbody>
                        <tfoot className='text-center h-2'>
                            <tr>
                                <td></td>
                                {/* <td></td>
                        <td></td> */}
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className='text-sm font-bold'>Total</td>
                                <td ref={totalRef} className='text-sm font-bold'>{totalValue.toFixed(2)}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Discount</td>
                                <td>
                                    <select ref={discountTypeRef} defaultValue='' class="rounded-lg text-center text-black text-xs  w-full h-8 max-w-xs" required>
                                        <option selected>%</option>
                                        <option>Fixed</option>
                                    </select>
                                </td>
                                <td> <input ref={discountRef} defaultValue={fixedDis} onChange={handleDiscount} type="number" placeholder="Type Discount" className="input input-bordered text-center text-xs w-28 h-8 mx-auto max-w-lg" /></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Rounding</td>
                                <td>
                                </td>
                                <td> <input onChange={handleRounding} defaultValue={roundingDis} type="number" placeholder="Type Discount" className="input input-bordered text-center text-xs w-28 h-8 mx-auto max-w-lg" /></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className='overflow-auto lg:col-span-2 ml-20 lg:ml-0'>
                    <div style={{ height: '400px' }} className='bg-red-600 w-48  text-xs text-center'>
                        <table class="table w-1/2 mx-auto">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Grand Total</th>
                                </tr>
                            </thead>
                        </table>
                        <input ref={grandTotalRef} type="number" value={isDiscount === true ? (total - discountAmount - roundingAmount).toFixed(2) : grandTotal.toFixed(2)} placeholder="Type here" className="input text-xs input-bordered text-center w-36 mx-auto max-w-lg" />
                        <table class="table w-1/2 mx-auto">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Cash Payment</th>
                                </tr>
                            </thead>
                        </table>
                        <input ref={paymentRef} onChange={handlePayment} defaultValue={updatePayment} type="number" placeholder="Type Payment" className="input text-xs input-bordered text-center w-36 mx-auto max-w-lg" />
                        <table class="table w-1/2 mx-auto">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Due Amount</th>
                                </tr>
                            </thead>
                        </table>
                        <input ref={dueAmountRef} type="number" value={isDiscount === false ? dueAmount.toFixed(2) : isDiscount & isPayment === true ? ((total - discountAmount - roundingAmount) - payment).toFixed(2) : ((total - discountAmount - roundingAmount) - payment).toFixed(2)} placeholder="Type here" className="input text-xs input-bordered text-center w-36 mx-auto max-w-lg" />
                        <button onClick={handleSubmitPosPurchase} className='btn btn-sm mt-4 text-white'>Submit Purchase</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdatePosPurchase;


// disabled={!isInvoice | !userAc?.addSales | details?.duplicate}



