import React, { useEffect, useState } from 'react';
import BookingTable from './BookingTable';
import UpdateModal from './UpdateModal';

const DeletedList = () => {
const [bookings, setBookings]= useState([]);
const [updateBooking, setUpdateBooking]= useState(null);

    useEffect(() => {
        fetch(`https://servers.clearsoftwares.net/demo/getcancelledbooking`)
            .then(res => res.json())
            .then(data => {
                setBookings(data);
            })
    }, [bookings])

    return (
        <div className='mt-8'>
            <h4 className='text-2xl text-red-600 font-bold text-center'>Cancelled Bookings ({bookings.length}pcs)</h4>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 my-8'>
                {
                    bookings.map(booking=><BookingTable
                    key={booking.id}
                    booking={booking}
                    setUpdateBooking={setUpdateBooking}
                    />)
                }
            </div>
            {
                updateBooking && <UpdateModal setUpdateBooking={setUpdateBooking} updateBooking={updateBooking}></UpdateModal>
            }
        </div>
    );
};

export default DeletedList;