// import React, { useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import useCurrentStock from '../../../../hooks/useCurrentStock';
import { useRef } from 'react';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import beep from '../../../../assets/audio/beep.mp3';
import CustomerList from '../../../../hooks/CustomerList';
import Loader from '../../../Shared/Loader';
import useCurrentStockWithoutSearch from '../../../../hooks/useCurrentStockWithoutSearch';
import useCurrentStockForPosSale from '../../../../hooks/useCurrentStockForPosSale';

const AddPosSales = () => {
    const { posCustomer } = useSelector((state) => state.salesInvoice);
    const userAc = useSelector((state) => state.user.user);
    const products = useSelector((state) => state.purchaseOrder.products);
    const [searchText, setSearchText] = useState();

    const handleSearchTermChange = (event) => {
        setSearchTerm(event?.target?.value);
        // setIsSearching(true)
    };


    const { isSearching, setIsSearching, searchTerm, setSearchTerm, filtered, isLoading } = useCurrentStockForPosSale(handleSearchTermChange);
    const navigate = useNavigate();

    useEffect(() => {
        const exactMatch = filtered.find(product => product.code === searchTerm);
        if (exactMatch) {
            // If an exact match is found, trigger the addProductToInvoice function
            handleAddProductToInvoice(exactMatch);
            setSearchTerm('');
            // setIsSearching(false)
        }
    }, [filtered]);

    const [isPayment, setIsPayment] = useState(false);
    const [isDiscount, setIsDiscount] = useState(false);
    const [discountAmount, setDiscountAmount] = useState('');
    const [payment, setPayment] = useState('');
    const [roundingAmount, setRoundingAmount] = useState(0);
    const [customers, setCustomers] = useState([]);
    const [salesCount, setSalesCount] = useState();
    const [loading, setLoading] = useState(true);
    const invoiceNo = `INV-${(salesCount?.result + 1).toString().padStart(6, '0')}`;


    const totalRef = useRef('');
    const discountRef = useRef();
    const grandTotalRef = useRef();
    const paymentRef = useRef();
    const dueAmountRef = useRef();
    const discountTypeRef = useRef();

    // const totalRef = useRef('');
    const invoiceRef = useRef('');
    const dateRef = useRef('')
    const beepAudioRef = useRef(null);

    const customerCodeRef = useRef('');
    const customerRef = useRef('');
    const addressRef = useRef('');
    const phoneRef = useRef('');
    const openingRef = useRef('');

    const date = new Date();
    const today = format(date, "yyyy-MM-dd");

    const [productHistory, setProductHistory] = useState([]);


    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/demo/getmaincustomers')
            .then(res => res.json())
            .then(data => {
                setCustomers(data);
                setLoading(false)
            })
    }, []);
    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/demo/getsalescount')
            .then(res => res.json())
            .then(data => {
                setSalesCount(data);
                console.log(data);
                setLoading(false)
            })
    }, []);

    // useEffect(() => {
    //     if (searchTerm && filtered.length > 0) {
    //         const matchedProduct = filtered.find(product => product.code === searchTerm);
    //         if (matchedProduct) {
    //             handleAddProductToInvoice(matchedProduct);
    //         }
    //     }
    // }, [searchTerm, filtered]);



    // State to store the modified array
    const [dataArray, setDataArray] = useState(products);

    // Function to calculate the default quantity
    const calculateDefaultQuantity = (minimum) => {
        return minimum * 2;
    };

    // Function to calculate the default total
    const calculateDefaultTotal = (defaultQuantity, sell) => {
        return defaultQuantity * sell;
    };

    // Set default values for quantity and total when the component mounts
    useEffect(() => {
        setDataArray(prevDataArray => {
            return prevDataArray.map(item => {
                const defaultQuantity = 1;
                const defaultTotal = calculateDefaultTotal(defaultQuantity, item.sell);
                // const cost = parseFloat(item.purchase) * parseFloat(item.quantity); // Calculate the cost
                return {
                    ...item,
                    // cost: cost.toFixed(2), // Add the cost property
                    quantity: item.quantity || defaultQuantity,
                    total: item.total || defaultTotal,
                };
            });
        });
    }, []);

    // Updated handleInputChange function
    const handleInputChange = (index, field, value) => {
        setDataArray((prevDataArray) => {
            const newDataArray = [...prevDataArray];
            newDataArray[index] = {
                ...newDataArray[index],
                [field]: value,
            };

            newDataArray[index].total =
                newDataArray[index].quantity * newDataArray[index].purchase;

            // Check if quantity exceeds stock
            if (value > newDataArray[index].stock && !newDataArray[index].isExceed) {
                newDataArray[index].isExceed = true; // Set isExceed to true
            } else if (value <= newDataArray[index].stock && newDataArray[index].isExceed) {
                newDataArray[index].isExceed = false; // Set isExceed to false if quantity is within stock
            }

            // Update costHistory dynamically based on the quantity
            const updatedCostHistory = [];

            let remainingQuantity = newDataArray[index].quantity;

            for (const lotEntry of productHistory) {
                const { lot, quantity, rate } = lotEntry;

                if (remainingQuantity > quantity) {
                    // If the remaining quantity is greater than the current lot quantity
                    updatedCostHistory.push({ lot, quantity, rate });
                    remainingQuantity -= quantity;
                } else {
                    // If the remaining quantity is less than or equal to the current lot quantity
                    updatedCostHistory.push({ lot, quantity: remainingQuantity, rate });
                    newDataArray[index].rate = rate; // Update the rate in the main array
                    newDataArray[index].lot = lot;  // Update the lot in the main array
                    break; // Exit the loop as we found the appropriate lot
                }
            }

            newDataArray[index].costHistory = updatedCostHistory;

            // Calculate the average cost based on the updated costHistory
            const totalCost = updatedCostHistory.reduce((totalCost, entry) => {
                return totalCost + entry.quantity * entry.rate;
            }, 0);

            const averageCost = totalCost / newDataArray[index].quantity;

            // Assign the average cost to the purchase property
            newDataArray[index].purchase = averageCost;
            newDataArray[index].total = totalCost;

            return newDataArray;
        });
    };

    // Function to remove an item from the dataArray
    const handleRemoveItem = index => {
        setDataArray(prevDataArray => {
            const newDataArray = [...prevDataArray];
            newDataArray.splice(index, 1);
            return newDataArray;
        });
    };
    const totalValue = dataArray.reduce((total, item) => total + (item.total || 0), 0);
    // Send the modified data to the server (e.g., on form submission)

    // const handleAddProductToInvoice = (product) => {
    //     const url = `https://servers.clearsoftwares.net/demo/getpurchaselot?code=${product.code}`;
    //     fetch(url)
    //         .then(res => res.json())
    //         .then(data => {
    //             setProductHistory(data);

    //             // Check if the product already exists in the dataArray
    //             const existingProductIndex = dataArray.findIndex((item) => item._id === product._id);

    //             if (existingProductIndex !== -1) {
    //                 // Raw Material already exists, update the quantity and total
    //                 const updatedDataArray = [...dataArray];
    //                 const defaultQuantity = 1;
    //                 updatedDataArray[existingProductIndex].quantity += 1;
    //                 updatedDataArray[existingProductIndex].total =
    //                     updatedDataArray[existingProductIndex].quantity * updatedDataArray[existingProductIndex].purchase;

    //                 // Update costHistory for the existing product
    //                 const updatedCostHistory = [...data];
    //                 updatedCostHistory.push({
    //                     lot: updatedCostHistory[0]?.lot,
    //                     quantity: 1,
    //                     rate: data[0]?.rate, // Use data[0]?.rate as the new value for rate
    //                 });

    //                 updatedDataArray[existingProductIndex].costHistory = updatedCostHistory;
    //                 setDataArray(updatedDataArray);
    //             } else {
    //                 // Raw Material doesn't exist, add it to the dataArray
    //                 setDataArray((prevDataArray) => {
    //                     const defaultQuantity = 1;
    //                     const defaultTotal = calculateDefaultTotal(defaultQuantity, data[0]?.rate);

    //                     // Create a new costHistory for the new product
    //                     const newCostHistory = [
    //                         {
    //                             lot: data[0]?.lot,
    //                             quantity: 1,
    //                             rate: data[0]?.rate,
    //                         },
    //                     ];

    //                     return [
    //                         ...prevDataArray,
    //                         { ...product, quantity: defaultQuantity, total: defaultTotal, rate: data[0]?.rate, costHistory: newCostHistory },
    //                     ];
    //                 });
    //             }

    //             setIsSearching(false);
    //             setSearchTerm('')

    //             if (beepAudioRef.current) {
    //                 beepAudioRef.current.play();
    //             }
    //         })
    //         .catch(error => {
    //             // Handle fetch error
    //             console.error('Error fetching data:', error);
    //         });
    // };

    // const handleAddProductToInvoice = (product) => {
    //     const url = `https://servers.clearsoftwares.net/demo/getpurchaselot?code=${product.code}`;
    //     fetch(url)
    //         .then(res => res.json())
    //         .then(data => {
    //             setProductHistory(data);

    //             // Check if the product already exists in the dataArray
    //             const existingProductIndex = dataArray.findIndex((item) => item._id === product._id);

    //             if (existingProductIndex !== -1) {
    //                 // Raw Material already exists, update the quantity and total
    //                 const updatedDataArray = [...dataArray];
    //                 updatedDataArray[existingProductIndex].quantity += 1;
    //                 updatedDataArray[existingProductIndex].total =
    //                     updatedDataArray[existingProductIndex].quantity * updatedDataArray[existingProductIndex].purchase;

    //                 // Check if the lot already exists in the costHistory array
    //                 const lotIndex = updatedDataArray[existingProductIndex].costHistory.findIndex(entry => entry.lot === data[0]?.lot);
    //                 if (lotIndex !== -1) {
    //                     // If the lot exists, update the quantity
    //                     updatedDataArray[existingProductIndex].costHistory[lotIndex].quantity += 1;
    //                 } else {
    //                     // If the lot doesn't exist, add a new entry
    //                     updatedDataArray[existingProductIndex].costHistory.push({
    //                         lot: data[0]?.lot,
    //                         quantity: 1,
    //                         rate: data[0]?.rate,
    //                     });
    //                 }

    //                 setDataArray(updatedDataArray);
    //             } else {
    //                 // Raw Material doesn't exist, add it to the dataArray
    //                 setDataArray((prevDataArray) => {
    //                     const defaultQuantity = 1;
    //                     const defaultTotal = calculateDefaultTotal(defaultQuantity, data[0]?.rate);

    //                     // Create a new costHistory for the new product
    //                     const newCostHistory = [
    //                         {
    //                             lot: data[0]?.lot,
    //                             quantity: 1,
    //                             rate: data[0]?.rate,
    //                         },
    //                     ];

    //                     return [
    //                         ...prevDataArray,
    //                         { ...product, quantity: defaultQuantity, total: defaultTotal, rate: data[0]?.rate, costHistory: newCostHistory },
    //                     ];
    //                 });
    //             }

    //             setIsSearching(false);
    //             setSearchTerm('')

    //             if (beepAudioRef.current) {
    //                 beepAudioRef.current.play();
    //             }
    //         })
    //         .catch(error => {
    //             // Handle fetch error
    //             console.error('Error fetching data:', error);
    //         });
    // };


    const handleAddProductToInvoice = (product) => {
        const url = `https://servers.clearsoftwares.net/demo/getpurchaselot?code=${product.code}`;
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setProductHistory(data);

                // Check if the product already exists in the dataArray
                const existingProductIndex = dataArray.findIndex((item) => item._id === product._id);

                if (existingProductIndex !== -1) {
                    // Raw Material already exists, update the quantity and total
                    const updatedDataArray = [...dataArray];
                    updatedDataArray[existingProductIndex].quantity += 1;
                    updatedDataArray[existingProductIndex].total =
                        updatedDataArray[existingProductIndex].quantity * updatedDataArray[existingProductIndex].purchase;

                    // Update costHistory for the existing product
                    const updatedCostHistory = [];
                    let remainingQuantity = updatedDataArray[existingProductIndex].quantity;

                    for (const lotEntry of data) {
                        const { lot, quantity, rate } = lotEntry;

                        if (remainingQuantity > quantity) {
                            // If the remaining quantity is greater than the current lot quantity
                            updatedCostHistory.push({ lot, quantity, rate });
                            remainingQuantity -= quantity;
                        } else {
                            // If the remaining quantity is less than or equal to the current lot quantity
                            updatedCostHistory.push({ lot, quantity: remainingQuantity, rate });
                            break; // Exit the loop as we found the appropriate lot
                        }
                    }

                    updatedDataArray[existingProductIndex].costHistory = updatedCostHistory;

                    // Check if quantity exceeds stock and update isExceed accordingly
                    if (updatedDataArray[existingProductIndex].quantity > updatedDataArray[existingProductIndex].stock) {
                        updatedDataArray[existingProductIndex].isExceed = true;
                    }

                    setDataArray(updatedDataArray);
                } else {
                    // Raw Material doesn't exist, add it to the dataArray
                    setDataArray((prevDataArray) => {
                        const defaultQuantity = 1;
                        const defaultTotal = calculateDefaultTotal(defaultQuantity, data[0]?.rate);

                        // Create a new costHistory for the new product
                        const newCostHistory = [
                            {
                                lot: data[0]?.lot,
                                quantity: 1,
                                rate: data[0]?.rate,
                            },
                        ];

                        // Check if quantity exceeds stock and set isExceed accordingly
                        const isExceed = defaultQuantity > product.stock;

                        return [
                            ...prevDataArray,
                            { ...product, quantity: defaultQuantity, total: defaultTotal, rate: data[0]?.rate, costHistory: newCostHistory, isExceed },
                        ];
                    });
                }

                setIsSearching(false);
                setSearchTerm('')

                if (beepAudioRef.current) {
                    beepAudioRef.current.play();
                }
            })
            .catch(error => {
                // Handle fetch error
                console.error('Error fetching data:', error);
            });
    };






    // New


    const handleDiscount = (event) => {
        const discount = parseFloat(event?.target?.value);
        const type = discountTypeRef.current.value;

        if (type === '%') {
            setDiscountAmount(total / 100 * discount)
        } else {
            setDiscountAmount(discount);
        }
        setIsDiscount(true);
    }

    const handleRounding = (event) => {
        const amount = parseFloat(event?.target?.value);
        setRoundingAmount(amount);
        setIsDiscount(true);
    }
    const handlePayment = (event) => {
        const payment = parseInt(event?.target?.value);
        setPayment(payment);
        setIsPayment(true);
        setIsDiscount(true);
    }

    const handleSubmitSales = () => {
        const salesInvoice = invoiceRef.current.value;
        const salesDate = dateRef.current.value;
        const customerCode = customerCodeRef.current.innerText;
        const salesCustomer = customerRef.current.innerText;
        const salesAddress = addressRef.current.innerText;
        const salesPhone = phoneRef.current.innerText;
        const salesOpening = parseFloat(openingRef.current.innerText);
        const totalAmount = parseInt(totalRef.current.innerText);
        const discount = discountAmount + roundingAmount;
        const grandTotal = parseInt(grandTotalRef.current.value);
        const paymentdata = parseInt(paymentRef.current.value);
        const payment = paymentdata ? parseInt(paymentRef.current.value) : 0;

        const isAdvance = payment === 0 & grandTotal <= Math.abs(salesOpening) ? true : false;
        const isPaid = payment + Math.abs(salesOpening) >= grandTotal ? true : false;

        const dueData = parseInt(dueAmountRef.current.value)
        const dueAmount = dueData ? parseInt(dueAmountRef.current.value) : 0;

        const user = userAc?.email.split('@')[0];
        let currentDate = new Date(salesDate);
        let monthName = currentDate.toLocaleString('default', { month: 'long' });
        let yearName = currentDate.toLocaleString('default', { year: 'numeric' });
        const month = monthName + " " + yearName;
        const type = 'Pos';
        const paid = dueAmount === 0 ? true : isAdvance === true ? true : isPaid ? true : false;

        const data = {
            products: dataArray, salesInvoice, salesDate, customerCode, salesCustomer, salesAddress,
            salesPhone, salesOpening, totalAmount, discount, fixedDis: discountAmount ? discountAmount : 0,
            roundingDis: roundingAmount ? roundingAmount : 0, grandTotal, payment, user, dueAmount, type, month, paid
        }


        fetch('https://servers.clearsoftwares.net/demo/sales', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Sales Added Successfull')
                navigate("/managedefaultsales")
                setTimeout(() => { window.location.reload(); }, 2000);
            })

    }



    const total = dataArray.reduce((total, currentValue) => total + parseFloat(currentValue?.total), 0);

    console.log(dataArray);

    if (loading) {
        return <Loader />
    }
    const isAnyExceed = dataArray.some(item => item.isExceed);


    return (
        <div className="mx-2 py-4">
            {/* <audio ref={beepAudioRef} src={beep} /> */}
            {/* <audio ref={beepErrorRef} src={error} /> */}
            <h1 className="text-lg font-bold mb-4">POS Sale</h1>
            <div className='flex'>
                <CustomerList customers={customers} />
            </div>
            <div className='flex flex-col lg:flex-row justify-start items-center gap-2'>
                <div className='lg:col-span-4 mt-7'>
                    <table class="table w-full mx-auto">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>Select Date</th>
                                <th className='bg-red-600 text-white normal-case'>Invoice No</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            <tr className=''>
                                <td> <input ref={dateRef} type="date" defaultValue={today} placeholder="Date" class="rounded-lg text-center text-xs bg-red-200 p-3 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required /></td>
                                <td><input ref={invoiceRef} value={invoiceNo} type="text" placeholder="Type Supplier Name Here" class="rounded-lg text-xs text-center bg-red-200 p-3 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='lg:col-span-7 overflow-x-auto w-full lg:w-auto lg:overflow-visible'>
                    <div className='sm:overflow-x-auto'>
                        <table class="table mx-auto text-xs">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Customer Code</th>
                                    <th className='bg-red-600 text-white normal-case'>Customer Name</th>
                                    <th className='bg-red-600 text-white normal-case'>Customer Address</th>
                                    <th className='bg-red-600 text-white normal-case'>Mobile No</th>
                                    <th className='bg-red-600 text-white normal-case'>Previews Due</th>
                                </tr>
                            </thead>
                            <tbody className='text-center'>
                                <tr className=''>
                                    <td ref={customerCodeRef} className='h-8'>{posCustomer?.code ? posCustomer?.code : '00001'}</td>
                                    <td ref={customerRef} className='h-8'>{posCustomer?.name ? posCustomer?.name : 'Walking Customer'}</td>
                                    <td ref={addressRef}>{posCustomer?.address ? posCustomer?.address : 'N/A'}</td>
                                    <td ref={phoneRef}>{posCustomer?.phone ? posCustomer?.address : 'N/A'}</td>
                                    <td ref={openingRef}>{posCustomer?.due ? (posCustomer?.due)?.toFixed(2) : '0.00'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className='flex justify-center lg:justify-start items-center my-4'>
                <div class="form-control w-60 mx-auto lg:mx-px">
                    <input onChange={handleSearchTermChange} value={searchTerm} type="text" placeholder="Scan Code/Search Product" class="input text-xs input-bordered border-dark-purple w-full max-w-xs" />
                </div>
            </div>

            <hr />
            <div style={isSearching ? {} : { visibility: 'hidden' }} className={`grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 bg-gray-200 p-2 ${isSearching ? 'h-60' : "h-8"} w-80 overflow-auto`}>
                {
                    filtered?.map(product =>
                        <label for="update-modal" onClick={() => handleAddProductToInvoice(product)} style={{ width: '300px', height: '50px' }} className={`bg-black bg-opacity-40 text-xs p-3 text-white  te  shadow-md flex justify-left items-center rounded-lg font-bold hover:bg-white hover:text-black cursor-pointer`}>{product?.code}-{product?.name}</label>)
                }
            </div>
            <div className='lg:grid lg:grid-cols-12 gap-2 h-screen'>
                <div className='overflow-auto lg:col-span-10 text-xs z-30 mb-4'>
                    <table class="table w-1/2 mx-auto text-xs">
                        <thead className='text-center'>
                            <tr>
                                <th className='bg-red-600 text-white normal-case'>SL#</th>
                                <th className='bg-red-600 text-white normal-case'>Product Code</th>
                                <th className='bg-red-600 text-white normal-case w-20'>Product Name</th>
                                <th className='bg-red-600 text-white normal-case'>Sale Quantity</th>
                                <th className='bg-red-600 text-white normal-case'>Unit</th>
                                <th className='bg-red-600 text-white normal-case'>Sale Rate</th>
                                <th className='bg-red-600 text-white normal-case'>Total</th>
                                <th className='bg-red-600 text-white normal-case'>Action</th>
                            </tr>
                        </thead>
                        <tbody className='text-center'>
                            {
                                dataArray?.map((product, index) => <tr key={product._id} className='hover'>
                                    <td className='h-8'>{index + 1}</td>
                                    <td className='h-8'>{product?.code}</td>
                                    <td title={`Available Stock: ${dataArray[index].stock}`} className='text-xs w-20'>{product?.name}</td>
                                    <td><input
                                        type="number"
                                        className={`border border-gray-300 px-2 py-1 rounded-md w-16 text-center ${dataArray[index].isExceed ? 'text-red-600' : ''}`}
                                        value={product.quantity || 0}
                                        onChange={e => handleInputChange(index, 'quantity', parseInt(e.target.value))}
                                    /></td>
                                    <td className='h-8 w-8'>{product?.unit}</td>
                                    <td> <input
                                        type="number"
                                        className="border border-gray-300 px-2 py-1 rounded-md w-20 text-center"
                                        value={product.sell || 0}
                                        onChange={e => handleInputChange(index, 'sell', parseFloat(e.target.value))}
                                    /></td>
                                    <td><input type="number" value={(product.total)?.toFixed(2) || 0} placeholder="Type here" className="input text-xs input-bordered text-center h-8 w-28 max-w-lg" readOnly /></td>
                                    <td> <button
                                        className="bg-red-500 hover:bg-red-600 text-white font-semibold px-4 py-2 rounded-md"
                                        onClick={() => handleRemoveItem(index)}
                                    >
                                        Remove
                                    </button> </td>
                                </tr>)
                            }

                        </tbody>
                        <tfoot className='text-center h-2'>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className='text-sm font-bold'>Total</td>
                                <td ref={totalRef} className='text-sm font-bold'>{totalValue.toFixed(2)}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Discount</td>
                                <td>
                                    <select ref={discountTypeRef} defaultValue='' class="rounded-lg text-center text-black text-xs  w-full h-8 max-w-xs" required>
                                        <option selected>%</option>
                                        <option>Fixed</option>
                                    </select>
                                </td>
                                <td> <input ref={discountRef} onChange={handleDiscount} type="number" placeholder="Type Discount" className="input input-bordered text-center text-xs w-28 h-8 mx-auto max-w-lg" /></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Rounding</td>
                                <td>
                                </td>
                                <td> <input onChange={handleRounding} type="number" placeholder="Type Discount" className="input input-bordered text-center text-xs w-28 h-8 mx-auto max-w-lg" /></td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className='overflow-auto lg:col-span-2 ml-20 lg:ml-0'>
                    <div style={{ height: '400px' }} className='bg-red-600 w-48  text-xs text-center'>
                        <table class="table w-1/2 mx-auto">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Grand Total</th>
                                </tr>
                            </thead>
                        </table>
                        <input ref={grandTotalRef} type="number" value={isDiscount === true ? (total - discountAmount - roundingAmount).toFixed(2) : total.toFixed(2)} placeholder="Type here" className="input text-xs input-bordered text-center w-36 mx-auto max-w-lg" />
                        <table class="table w-1/2 mx-auto">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Cash Payment</th>
                                </tr>
                            </thead>
                        </table>
                        <input ref={paymentRef} onChange={handlePayment} type="number" placeholder="Type Payment" className="input text-xs input-bordered text-center w-36 mx-auto max-w-lg" />
                        <table class="table w-1/2 mx-auto">
                            <thead className='text-center'>
                                <tr>
                                    <th className='bg-red-600 text-white normal-case'>Due Amount</th>
                                </tr>
                            </thead>
                        </table>
                        <input ref={dueAmountRef} type="number" value={isDiscount === false ? total.toFixed(2) : isDiscount & isPayment === true ? ((total - discountAmount - roundingAmount) - payment).toFixed(2) : ((total - discountAmount - roundingAmount) - payment).toFixed(2)} placeholder="Type here" className="input text-xs input-bordered text-center w-36 mx-auto max-w-lg" />
                        <button onClick={handleSubmitSales} disabled={isAnyExceed} className='btn btn-sm mt-4 text-white'>Add Sales</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddPosSales;



