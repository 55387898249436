import { render } from '@testing-library/react';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { clearEdit } from '../../../features/InfoSlice/infoSlice';
import Loader from '../../Shared/Loader';


const UpdateModal = ({ updateProduct, setUpdateProduct, refetchData, pageNo }) => {
    const userAc = useSelector((state) => state.user.user);
    const setting = useSelector((state) => state.info.settings);
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [units, setUnits] = useState([]);
    const [origins, setOrigins] = useState([]);
    const [loading, setLoading] = useState(true);
    // const Product = useSelector((state) => state.info.Product[0]);
    const { _id, name, code, category, brand, origin, unit, purchase, sell, minimum, opening, status } = updateProduct;

    const onSubmit = (data) => {
        const name = data.name;
        const code = data.code;
        const category = data.category;
        const brand = data.brand;
        const origin = data.origin;
        const unit = data.unit;
        const purchase = parseFloat(data.purchase);
        const sell = parseFloat(data.sell);
        const minimum = parseFloat(data.minimum);
        const opening = parseFloat(data.opening);
        const user = userAc?.email.split('@')[0]
        const status = data.status;
        const approvedBy = false;
        const newData = { name, code, category, brand, origin, unit, purchase, sell, minimum, opening, user, status, approvedBy }


        fetch(`https://servers.clearsoftwares.net/demo/getproducts/${_id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(newData)
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                setUpdateProduct(null);
                toast('Product Upadated Successful');
                refetchData(pageNo);
                // window.location.reload(false)
            })
    };

    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/demo/getcategories')
            .then(res => res.json())
            .then(data => {
                setCategories(data);
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/demo/getbrands')
            .then(res => res.json())
            .then(data => {
                setBrands(data);
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/demo/getunits')
            .then(res => res.json())
            .then(data => {
                setUnits(data);
                setLoading(false)
            })
    }, [])
    useEffect(() => {
        fetch('https://servers.clearsoftwares.net/demo/getorigins')
            .then(res => res.json())
            .then(data => {
                setOrigins(data);
                setLoading(false)
            })
    }, [])

    if (loading) {
        return <Loader />
    }

    const noEdit = () => {
        window.location.reload(false)
    }

    return (
        <div>
            <input type="checkbox" id="update-modal" class="modal-toggle" />
            <div class="modal mt-12 text-xs">
                <div class="modal-box max-w-none lg:w-8/12 lg:ml-60 relative">
                    <label for="update-modal" onClick={() => noEdit()} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    <h3 class="text-lg font-bold">Update-{name}</h3>
                    <form onSubmit={handleSubmit(onSubmit)} className='p-5 grid grid-cols-1 lg:grid-cols-3 gap-4 justify-items-center'>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Product Name</span>
                            </label>
                            <input {...register("name")} type="text" defaultValue={name} placeholder="Type Product Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Product Code</span>
                            </label>
                            <input {...register("code")} type="text" value={code} placeholder="Type Product Code Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Product Category</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("category")} defaultValue={category} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                {
                                    categories.map(category => <option> {category.name}</option>)
                                }

                            </select>
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Product Brand</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("brand")} defaultValue={brand} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                {
                                    brands.map(brand => <option>{brand.name}</option>)
                                }
                            </select>
                            {/* <input list='data' style={{ padding: '18px' }} {...register("brand")} placeholder="Select Brand" class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required />
                    <datalist id='data' class=" rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs">
                        {
                            categories.map(category => <option className='bg-white w-full'>{category.name}</option>)
                        }
                    </datalist> */}
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Product Origin</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("origin")} defaultValue={origin} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                {
                                    origins.map(origin => <option>{origin.name}</option>)
                                }

                            </select>
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Product Unit</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("unit")} defaultValue={unit} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                {
                                    units.map(unit => <option>{unit.type}</option>)
                                }

                            </select>
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Purchase Price</span>
                            </label>
                            <input {...register("purchase")} type="text" defaultValue={purchase} placeholder="Purchase Rate" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Sell Price</span>
                            </label>
                            <input {...register("sell")} type="text" defaultValue={sell} placeholder="Sell Price" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Minimum Stock</span>
                            </label>
                            <input {...register("minimum")} type="number" defaultValue={minimum} placeholder="Minimum Stock" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Opening Stock</span>
                            </label>
                            <input {...register("opening")} type="number" {...(setting?.Update_Product_Opening ? { defaultValue: opening } : { value: opening })} placeholder="Opening Stock" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                        </div>
                        <div class="form-control w-full max-w-xs">
                            <label class="label">
                                <span class="label-text text-sm">Status</span>
                            </label>
                            <select style={{ padding: '18px' }} {...register("status")} defaultValue={status} class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                                <option selected>Active</option>
                                <option>Inactive</option>
                            </select>
                        </div>
                        <input type='submit' value='Update Product' className='btn bg-red-600 lg:mt-9 lg:mr-28 text-white' />
                    </form>
                </div>
            </div>
        </div >
    );
};

export default UpdateModal;