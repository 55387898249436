import { Chart } from "react-google-charts";
import React from 'react';
import useFetchData from "../../../hooks/useFetchData";

const ProfitExpenseNett = () => {
const {items:expenses}=useFetchData("https://servers.clearsoftwares.net/demo/monthwiseexpenseincome");
const { items: profit } = useFetchData("https://servers.clearsoftwares.net/demo/daywiseprofit");
    const totalProfit = profit?.reduce((total, currentValue) => total + parseInt(currentValue?.profit), 0);
    
    const totalExpense = expenses?.reduce((total, expense) => {
        if (expense?.thismonthexpense) {
            return total + parseFloat(expense?.thismonthexpense);
        }
        return total;
    }, 0) || 0;
    const totalIncome = expenses?.reduce((total, income) => {
        if (income?.thismonthincome) {
            return total + parseFloat(income?.thismonthincome);
        }
        return total;
    }, 0) || 0;

    // const income=totalProfit+totalIncome;
    const nettProfit=totalProfit+totalIncome-totalExpense;

    // console.log(totalProfit);

    const data = [
        ["Task", "Profit Expense Ratio"],
        ["Profit", nettProfit],
        ["Expense", totalExpense],
    ];

    const options = {
        title: "Profit Expense Ratio (This Month)",
        pieHole: 0.2,
    };
    return (
        <div className="mt-8">
            <Chart
                chartType="PieChart"
                data={data}
                options={options}
                width={"100%"}
                height={"300px"}
            />
        </div>
    );
};

export default ProfitExpenseNett;