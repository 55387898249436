import api from "../api/apiSlice";

const productApi = api.injectEndpoints({
    endpoints: (builder) => ({
        postProduct: builder.mutation({
            query: (data) => ({
                url: '/products',
                method: 'POST',
                body: data,
            })
        }),
        getProducts: builder.query({
            query: () => '/getproductswithfilter',
            providesTags: [],
        }),
    })
});

export const { usePostProductMutation, useGetProductsQuery } = productApi;



// updateBook: builder.mutation({
        //     query: ({ data, id }) => ({
        //         url: `/book/${id}`,
        //         method: 'PUT',
        //         body: data,
        //     }),
        //     invalidatesTags: ['updatebook']
        // }),
        // deleteBook: builder.mutation({
        //     query: (id) => ({
        //         url: `/book/${id}`,
        //         method: 'DELETE',
        //     }),
        //     invalidatesTags: ['deletebook']
        // }),