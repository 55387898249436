import React, { useEffect, useState } from 'react';
import BankWithdrawField from './BankWithdrawField';


const AddBankWithdraw = () => {
     return (
        <div className='mx-auto p-2 mt-8'>
            <h1 className='text-md text-center bg-red-600 p-3 text-white rounded-xl'>Add A Bank Withdraw</h1>
            <BankWithdrawField></BankWithdrawField>
        </div>
    );
};

export default AddBankWithdraw;