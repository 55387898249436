import React, { useState } from 'react';
import Barcode from 'react-barcode';

const BarCodeGen = () => {
  const [barcodeValue, setBarcodeValue] = useState('');
  const [quantity, setQuantity] = useState(1);

  const handleInputChange = (e) => {
    setBarcodeValue(e.target.value);
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  return (
    <div className="App bg-gray-100 min-h-screen p-4">
      <h1 className="text-2xl font-semibold mb-4">Barcode Generator with Quantity</h1>
      <div className="mb-4">
        <label className="block text-gray-700">Enter Barcode Value:</label>
        <input
          type="text"
          className="border rounded-md p-2 w-full"
          value={barcodeValue}
          onChange={handleInputChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">Quantity:</label>
        <input
          type="number"
          className="border rounded-md p-2 w-full"
          value={quantity}
          onChange={handleQuantityChange}
        />
      </div>
      <div className="flex flex-wrap -mx-2">
        {Array.from({ length: quantity }).map((_, index) => (
          <div key={index} className="w-2/12 my-2 mx-auto bg-white shadow-xl">
              <div style={{marginBottom:'-30px'}} className='shadow-2xl bg-white w-full'>Blue Bird 3002</div>
              <div className='px-8'  style={{ display: 'flex', justifyContent: 'center',margin:'0' }}>
                <Barcode className='shadow-xl' value={barcodeValue} />
              </div>
              <div style={{marginTop:'-30px'}} className='shadow-xl bg-white w-full'>SRR TK-1290/-</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarCodeGen;
