import { useState, useEffect } from 'react';

const useFetchData = (url) => {
    const [items, setitems] = useState([]);

    useEffect(() => {
        fetch(`${url}`)
            .then(res => res.json())
            .then(data => {
                setitems(data);
            })
    }, [])

    return {items};
};

export default useFetchData;
