import React, { useEffect, useState } from 'react';
import LoanReturnField from './LoanReturnField';


const AddLoanReturn = () => {
     return (
        <div className='mx-auto p-2 mt-8'>
            <h1 className='text-md text-center bg-red-600 p-3 text-white rounded-xl'>Add A Loan Return</h1>
            <LoanReturnField></LoanReturnField>
        </div>
    );
};

export default AddLoanReturn;