import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import logo from '../../../../../assets/images/logo.jpg';
import BusinessInfo from '../../../../Shared/BusinessInfo';

// import { ComponentToPrint } from './ComponentToPrint';


const ViewInvoice = ({ purchaseModal, setPurchaseModal }) => {
    const componentRef = useRef();
    const { discount, dueAmount, grandTotal, payment, products, purchaseReturnDate, purchaseInvoice,
        purchaseSupplier, totalAmount, purchaseAddress } = purchaseModal;

    console.log(componentRef.current)

    const closeModal = () => {
        setPurchaseModal(null);
    }



    const generatePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Purchase Inovice No-${purchaseInvoice}`,
    })

    return (
        <>
            <div className="z-50">
                <input type="checkbox" id="view-modal" class="modal-toggle" />
                <div class="modal mt-12">
                    <div class="modal-box max-w-none lg:w-8/12 lg:ml-60 relative">
                        <div className='text-right'>
                            <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button>
                        </div>
                        <div ref={componentRef}>
                            <div className='flex justify-center items-center mt-8'>
                                <div>
                                    <img src={BusinessInfo.img} className="w-20 lg:w-44 lg:mr-20 lg:mb-4" alt="" />
                                </div>
                                <div className='lg:mr-40'>
                                    <h1 className='text-xl lg:text-4xl text-black text-center'>{BusinessInfo.fullName}</h1>
                                    <h1 className='text-sm lg:text-xl text-black text-center'>{BusinessInfo.dealer}</h1>
                                    <h1 className='text-xs text-black text-center'>{BusinessInfo.address}</h1>
                                    <h1 className='text-xs lg:text-xl text-black text-center'>{BusinessInfo.mobile}</h1>
                                </div>
                            </div>
                            <div class="flex items-center justify-between">
                                <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                                <hr class="border-b border-gray-300 w-full mt-4 mb-px" />
                            </div>
                            <div class="flex items-center justify-between">
                                <hr class="border-b border-gray-300 w-full my-px" />
                                <hr class="border-b border-gray-300 w-full my-px" />
                            </div>
                            <label for="view-modal" onClick={closeModal} class="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                            <h3 class="text-2xl uppercase text-center font-bold mt-8">Purchase Return Invoice</h3>
                            <div className='text-left ml-20 text-xs'>
                                <p><span style={{ marginRight: '31px' }} className='font-bold'>Invoice No:</span>{purchaseInvoice}</p>
                                <p><span style={{ marginRight: '71px' }} className='font-bold'>Date:</span>  {purchaseReturnDate}</p>
                                <p><span className='font-bold mr-12'>Supplier:</span>{purchaseSupplier}</p>
                                <p><span className='font-bold mr-12'>Address:</span>{purchaseAddress}</p>
                                {/* <ReactToPrint
                trigger={() => <button className='text-2xl text-black'>Print this out!</button>}
                content={() => componentRef.current}/> */}
                            </div>
                            <div className='overflow-auto'>
                                <table class="table w-1/2 mt-8 mx-auto text-xs">
                                    <thead className='text-center'>
                                        <tr>
                                            <th className='bg-red-600 text-white normal-case'>SL#</th>
                                            <th className='bg-red-600 text-white normal-case'>Product Code</th>
                                            <th className='bg-red-600 text-white normal-case'>Product Name</th>
                                            <th className='bg-red-600 text-white normal-case'>Quantity</th>
                                            <th className='bg-red-600 text-white normal-case'>Rate</th>
                                            <th className='bg-red-600 text-white normal-case'>Total</th>
                                        </tr>
                                    </thead>
                                    <tbody className='text-center'>
                                        {
                                            products.map((product, index) => <tr className=''>
                                                <td>{index + 1}</td>
                                                <td>{product.code}</td>
                                                <td>{product.name}</td>
                                                <td>{product.returnQuantity}</td>
                                                <td>{product.rate}</td>
                                                <td >{product.total}</td>
                                            </tr>)
                                        }

                                    </tbody>
                                    <tbody className='text-center bg-white'>
                                        <tr className='border-t-2 border-black'>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td ></td>
                                            <td >Total</td>
                                            <td className='font-bold'>{totalAmount}</td>
                                        </tr>
                                        <tr className=''>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>Discount</td>
                                            <td className='font-bold'>{discount}</td>
                                        </tr>
                                        <tr className=''>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className='font-bold'>Grand Amount</td>
                                            <td className='font-bold'>{grandTotal}</td>
                                        </tr>
                                        <tr className=''>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>Payment</td>
                                            <td className='font-bold'>{payment}</td>
                                        </tr>
                                        <tr className=''>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>Total Due</td>
                                            <td className='font-bold'>{dueAmount}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div >
            </div >
        </>
    );
};

export default ViewInvoice;