import { createSlice } from "@reduxjs/toolkit"



const initialState={
    slot:[],
    date:'',
    customer:[],

}

const bookingSlice= createSlice({
    name: 'booking',
    initialState,
    reducers:{
        getSlot:(state, action)=>{
            state.slot.push(action.payload);
        },
        getingDate:(state, action)=>{
            state.date=action.payload;
        },
        getCustomer:(state,action)=>{
            state.customer=action.payload;
        }
    }
})

export const {getSlot, getingDate,getCustomer}= bookingSlice.actions;
export default bookingSlice.reducer;
