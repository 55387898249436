import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

const useCurrentStockForPosSale = (handleSearchTermChange) => {
    const [sales, setSales] = useState([]);
    const [purchases, setPurchases] = useState([]);
    const [returnSales, setReturnSales] = useState([]);
    const [returnPurchase, setReturnPurchase] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isSearching, setIsSearching] = useState(false);


    // const handleSearchResult = (event) => {
    //     const searchText = event.target.value.toLowerCase(); // Convert search text to lowercase
    //     const match = stockData.filter(product =>
    //         product.name.toLowerCase().includes(searchText) ||
    //         product.code.includes(searchText)
    //     );
    //     setSearchText(searchText); // Update search text state
    //     setSearchResult(match);
    //     setIsSearching(true);
    //     const exactMatch = match.find(product => product.code === searchText);

    //     if (exactMatch) {
    //         // If an exact match is found, trigger the addProductToInvoice function
    //         handleAddProductToInvoice(exactMatch);
    //     }
    // }

    // const handleSearchTermChange = (event) => {
    //     setSearchTerm(event.target.value);
    //     setIsSearching(true)
    // };

    // useEffect(() => {
    //     if (!isSearching) {
    //         setSearchTerm('');
    //     }
    // }, [isSearching]);

    useEffect(() => {
        // Example usage: call handleSearchTermChange when isSearching changes
        handleSearchTermChange(/* pass any necessary arguments */);
    }, [isSearching]);


    useEffect(() => {
        const url = `https://servers.clearsoftwares.net/demo/getsalesproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setSales(data);
            })
    }, []);

    useEffect(() => {
        const url = `https://servers.clearsoftwares.net/demo/getpurchaseproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setPurchases(data);
            })
    }, []);

    useEffect(() => {
        const url = `https://servers.clearsoftwares.net/demo/getreturnsalesproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setReturnSales(data);
            })
    }, []);
    useEffect(() => {
        const url = `https://servers.clearsoftwares.net/demo/getreturnpurchaseproducts`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                setReturnPurchase(data);
            })
    }, []);

    // const { data: products, isLoading } = useQuery(['products', searchTerm], () => fetch(`https://servers.clearsoftwares.net/demo/getproductsforstock?searchTerm=${encodeURIComponent(searchTerm)}`).then(res => res.json()));

    const { data: products, isLoading } = useQuery(
        ['products', searchTerm], // Include the searchTerm in the query key
        () => {
            // Fetch products only if there's a search term; otherwise, return an empty array
            if (searchTerm) {
                return fetch(`https://servers.clearsoftwares.net/demo/getproductsforstock?searchTerm=${encodeURIComponent(searchTerm)}`).then(
                    (res) => res.json()
                );
                // return fetch(`https://servers.clearsoftwares.net/demo/getproductsforpossale?search=${searchTerm}&category=''&page=1&limit=20`).then(
                //     (res) => res.json()
                // );
            } else {
                return Promise.resolve([]); // Return an empty array when there's no search term
            }
        }
    );

    // Stock Report
    // Sales Quantity

    let array = [];

    for (let i = 0; i < products?.length; i++) {
        const filtered = sales.filter(sale => sale?.code === products[i]?.code);
        array.push(filtered);
    }

    let array2 = [];
    for (let i = 0; i < array?.length; i++) {
        const salesQuantity = array[i]?.map(a => parseFloat(a.quantity));
        const totalSales = salesQuantity?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0);
        array2.push(totalSales);
    }

    // Purchase Quantity

    let array3 = [];
    for (let i = 0; i < products?.length; i++) {
        const filtered = purchases.filter(purchase => purchase.code === products[i].code);
        array3.push(filtered);
    }

    let array4 = [];

    for (let i = 0; i < array?.length; i++) {
        let loanReturnAccounts = array3[i]?.map(a => parseFloat(a.quantity));
        const totalLoanReturn = loanReturnAccounts?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        array4.push(totalLoanReturn)
    }

    // Return Sales Quantity
    let array5 = [];
    for (let i = 0; i < products?.length; i++) {
        const filtered = returnSales.filter(rs => rs.code === products[i].code);
        array5.push(filtered);
    }

    let array6 = [];

    for (let i = 0; i < array?.length; i++) {
        let loanReturnAccounts = array5[i]?.map(a => parseFloat(a.returnQuantity));
        const totalLoanReturn = loanReturnAccounts?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        array6.push(totalLoanReturn)
    }

    // Return Purchase Quantity
    let array7 = [];
    for (let i = 0; i < products?.length; i++) {
        const filtered = returnPurchase.filter(rp => rp.code === products[i].code);
        array7.push(filtered);
    }

    let array8 = [];

    for (let i = 0; i < array?.length; i++) {
        let loanReturnAccounts = array7[i]?.map(a => parseFloat(a.returnQuantity));
        const totalLoanReturn = loanReturnAccounts?.reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        array8.push(totalLoanReturn)
    }



    // Joining Balance To data

    let stockData = [];
    for (let i = 0; i < products?.length; i++) {
        const product = ({
            _id: products[i]._id, name: products[i]?.name,
            code: products[i].code, category: products[i].category,
            brand: products[i].brand, minimum: products[i].minimum,
            origin: products[i].origin, unit: products[i].unit, status: products[i].status,
            purchase: products[i].purchase, sell: products[i].sell, totalSale: array2[i],
            totalPurchase: array4[i], opening: products[i].opening,
            totalReturn: array6[i], totalPurchaseReturn: array8[i],
            stock: (products[i]?.opening) + array4[i] + array6[i] - array2[i] - array8[i], stockCost: (products[i].opening
                + array4[i] + array6[i] - array2[i] - array8[i]) * products[i].purchase,
        });

        stockData.push(product);
    }

    const filtered = stockData?.filter((product) => product.stock > 0);

    // const filteredProducts = filtered.filter((product) =>
    //     product.name.toLowerCase().includes(searchTerm.toLowerCase())
    // );
    const filteredProducts = filtered.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.code.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const filteredStockData = stockData.filter((product) =>
        product.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return { filtered: filteredProducts, stockData: filteredStockData, isLoading, searchTerm, setSearchTerm, handleSearchTermChange, isSearching, setIsSearching };



    // if (isLoading) {
    //     return <Loader />
    // }

    // return { filtered, stockData, isLoading };
};

export default useCurrentStockForPosSale;
