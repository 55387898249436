import { createSlice } from "@reduxjs/toolkit"


const initialState = {
    products: [],
    productList: [],
    quantity: [],
    duplicateError: false,
    supplier: [],
    updatePurchase: [],
    details: {},
    posSupplier: [],
    isPos: false,
    isSupplier: false,
    printProducts: [],
    returnPurchase: [],
    editReturnPurchase: [],

}

const PurchaseSlice = createSlice({
    name: 'purchaseInvoice',
    initialState,
    reducers: {
        addToPurchaseInvoice: (state, action) => {
            const newProduct = { ...action.payload, quantity: 1 };
            if (state.products.length) {
                state.products[0] = newProduct;
            } else {
                state.products.unshift(newProduct);
            }
        },
        addToProductList: (state, action) => {
            const selectedProduct = state.productList.find(product => product.code === action.payload.code);
            if (!selectedProduct) {
                const newOne = action.payload;
                state.productList.push(newOne);
            }
            // else{
            //     state.duplicateError=true;
            // }
        },
        addQuantiy: (state, action) => {
            const selectedProduct = state.products.find(product => product._id === action.payload.id);
            if (selectedProduct) {
                state.products.quantity = action.payload.quantity;
            } else {
                state.products.quantity = 1;
            }
        },
        removeProductFromList: (state, action) => {
            const selectedProduct = state.productList.filter(product => product.code !== action.payload.code);
            state.productList = selectedProduct;
        },
        selectSupplier: (state, action) => {
            state.supplier = action.payload;
        },
        updatePurchase: (state, action) => {
            state.updatePurchase = action.payload;
        },
        addToUpdatePurchase: (state, action) => {
            const selectedProduct = state.updatePurchase.products.find(product => product.code === action.payload.code);
            if (!selectedProduct) {
                const newProducts = action.payload;
                state.updatePurchase.products.push(newProducts);
            }
        },
        removeFromUpdateList: (state, action) => {
            const selectedProduct = state.updatePurchase.products.filter(product => product.code !== action.payload.code);
            state.updatePurchase.products = selectedProduct;
        },
        addUpdateDetails: (state, action) => {
            state.details = action.payload;
        },
        selectPosSupplier: (state, action) => {
            state.posSupplier = action.payload;
            state.isPos = true;
            state.isSupplier = true;
        },
        printBarCodes: (state, action) => {
            state.printProducts = action.payload;
        },

        returnPurchase: (state, action) => {
            state.returnPurchase = action.payload;
        },
        updateReturnPurchase: (state, action) => {
            state.returnPurchase = action.payload;
        },
        editReturnPurchase: (state, action) => {
            state.editReturnPurchase = action.payload;
        },
        updateEditReturnPurchase: (state, action) => {
            state.editReturnPurchase = action.payload;
        },
        removeFromReturnPurchaseList: (state, action) => {
            const selectedProduct = state.returnPurchase.products.filter(product => product.code !== action.payload.code);
            state.returnPurchase.products = selectedProduct;
        },

    }
});

export const { addToPurchaseInvoice, addToProductList, addQuantiy, removeProductFromList,
    selectSupplier, updatePurchase, addToUpdatePurchase, removeFromUpdateList, addUpdateDetails,
    selectPosSupplier, printBarCodes, returnPurchase, updateReturnPurchase, editReturnPurchase, updateEditReturnPurchase
    , removeFromReturnPurchaseList } = PurchaseSlice.actions;
export default PurchaseSlice.reducer;