import logo from '../../assets/images/logo.jpg'

const BusinessInfo = {
    name: "Clear Pos",
    fullName: "Clear Software Solution",
    dealer: "(Pos Accounting Softwares, Ecommarce Website Provider)",
    address: "Mawna Chowrasta, Sreepur, Gazipur",
    mobile: "Mobile: 01310751276, 01772545974",
    img: logo,
}

export default BusinessInfo;
