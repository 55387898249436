// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC0QJzJePLv7imctENa-fiAa5MuP4Dgxls",
  authDomain: "nbc-motors-service-booking.firebaseapp.com",
  projectId: "nbc-motors-service-booking",
  storageBucket: "nbc-motors-service-booking.appspot.com",
  messagingSenderId: "218418245114",
  appId: "1:218418245114:web:abef7de33654c204418843"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth= getAuth(app);

export default auth;