import { sl } from 'date-fns/locale';
import React from 'react';

const BookingTable = ({ booking, setUpdateBooking }) => {
    // const { name, slots, price } = booking;
    // console.log(booking);

    
    return (
        <div className="card lg:max-w-lg bg-base-100 shadow-xl text-left">
            <div className="card-body">
                <h2 className="card-title mx-auto">Name: {booking.person_name}, Id:{booking._id.slice(1,8)}</h2>
                <h3 className='text-xl'>Phone: {booking.person_phone}</h3>
                <h3 className='text-xl'>{booking.service}</h3>
                <p>Booking Date: {booking.booking_date}</p>
                <p>Slot: {booking.time_slot}</p>
                <p>Bike Model: {booking.model}</p>
                <p>Completed By: {booking?.technician}</p>
                <p>Pit No: {booking?.pit}, <span>Date:{booking?.date}</span> <span>Time: {booking?.time}</span></p>
            </div>
        </div>
    );
};

export default BookingTable;