import React, { useEffect, useState } from 'react';
import BankAccountField from './BankAccountField';


const AddBankAccount = () => {
     return (
        <div className='mx-auto w-full p-2 mt-8'>
            <h1 className='text-md text-center bg-red-600 p-3 text-white rounded-xl'>Add A Bank Account</h1>
            <BankAccountField></BankAccountField>
        </div>
    );
};

export default AddBankAccount;