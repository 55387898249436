import React, { useEffect, useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import { toast } from 'react-toastify';
import UpdateModal from './UpdateModal';
import { useDispatch, useSelector } from 'react-redux';
import { FaEdit } from 'react-icons/fa';
import { AiFillDelete, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { editSupplier } from '../../../features/InfoSlice/infoSlice';
import Loader from '../../Shared/Loader';
import usePaginationSearch from '../../../hooks/usePaginationSearch';
import ReactPaginate from 'react-paginate';
import person from '../../../assets/images/person.jpeg'
import ImageModal from '../../Shared/ImageModal';
import useFetchData from '../../../hooks/useFetchData'



const CustomerField = ({ }) => {
    const userAc = useSelector((state) => state.user.user);
    const setting = useSelector((state) => state.info.settings);
    const { items, pageCount, limit, pageNo, loading, handleSearchResult,
        handleLimit, handlePageClick, totalItem, refetchData } = usePaginationSearch('https://servers.clearsoftwares.net/demo/getmaincustomerswithfilter')
    const { register, handleSubmit, reset, watch, formState: { errors } } = useForm();
    const [updateCustomer, setUpdateCustomer] = useState(null);
    const dispatch = useDispatch();
    const [isExpend, setIsExpend] = useState(false);
    const customerNoRef = useRef('')
    const [id, setId] = useState('');
    const { items: mainCustomers } = useFetchData('https://servers.clearsoftwares.net/demo/getmaincustomers');

    // const [showImageModal, setShowImageModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const [customersNo, setCustomerNo] = useState('');
    useEffect(() => {
        if (setting?.Auto_Customer_Code) {
            const myLength = 'CUS-' + (mainCustomers?.length + 1).toString().padStart(5, '0');
            console.log(myLength);
            const isExist = mainCustomers?.filter(cus => cus?.code === myLength);
            const duplicate = isExist?.length ? true : false;

            if (duplicate) {
                // Increment the suffix to avoid duplicates
                const suffix = isExist.length + 1;
                setCustomerNo(`${myLength}-${suffix}`);
            } else {
                setCustomerNo(myLength);
            }
        } else {
            setCustomerNo('');
        }
    }, [mainCustomers]);


    function guardarArchivo(e) {
        var file = e.target.files[0] //the file
        var reader = new FileReader() //this for convert to Base64 
        reader.readAsDataURL(e.target.files[0]) //start conversion...
        reader.onload = function (e) { //.. once finished..
            var rawLog = reader.result.split(',')[1]; //extract only thee file data part
            var dataSend = { dataReq: { data: rawLog, name: file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
            fetch('https://script.google.com/macros/s/AKfycbwYKgy85mYgpiT7U3mV2T6vum0yKg_I-AAJegLuNfeosEO_HligOW_ic_M1mW8pUqk/exec', //your AppsScript URL
                { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
                .then(res => res.json()).then((a) => {
                    if (a.id) {
                        toast.success("ছবি আপলোড হয়েছে")
                        setId(a.id)

                    }
                }).catch(e => console.log(e)) // Or Error in console
        }
    }

    // const customerCode = (totalItem + 1).toString().padStart(5, '0');
    console.log();

    if (loading) {
        return <Loader />
    }

    const onSubmit = data => {
        const user = userAc?.email.split('@')[0];
        const code = customerNoRef?.current?.value;
        const link = `https://docs.google.com/uc?export=download&id=${id}`

        const requestBody = id
            ? { user, code, ...data, img: link }
            : { user, code, ...data };
        // console.log(code);
        fetch('https://servers.clearsoftwares.net/demo/addmaincustomer', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(requestBody),
        })
            .then(res => res.json())
            .then(data => {
                toast.success('Customer Added')
                reset();
                refetchData();
                setIsExpend(false);
                setTimeout(() => { window.location.reload(); }, 3000);
            })

    }

    const handleDeleteCustomer = (id) => {
        const proceed = window.confirm('Are You Sure Want To Delete')
        if (proceed) {
            fetch(`https://servers.clearsoftwares.net/demo/getmaincustomer/${id}`, {
                method: 'DELETE'
            })
                .then(res => res.json())
                .then(data => {
                    toast.success('Delete Successful')
                    setUpdateCustomer(null);
                    refetchData(pageNo)
                    setTimeout(() => { window.location.reload(); }, 2000);
                })
        }
    };

    const handleUpdateCustomer = (customer) => {

        // fetch(`https://servers.clearsoftwares.net/demo/getsuppliers/${Supplier._id}`)
        //     .then(res => res.json())
        //     .then(data => {
        //         dispatch(editSupplier(data))
        //     })
        setUpdateCustomer(customer);
    }

    const handleApprove = (customer) => {
        const approvedBy = userAc?.email.split('@')[0];
        fetch(`https://servers.clearsoftwares.net/demo/getmaincustomer/${customer._id}`, {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({ approvedBy })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                toast('Customer Approved');
                refetchData(pageNo)
            })
    }

    return (
        <div>
            <div className={`${isExpend ? 'shadow-2xl' : ''} px-12 pb-5 mt-5 text-xs`}>
                <div className='text-right'><button onClick={() => setIsExpend(!isExpend)} className='btn btn-sm btn-error text-white'>{isExpend === true ? <AiOutlineMinus className='text-lg font-bold' /> : <AiOutlinePlus className='text-lg font-bold' />}Add Customer</button>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className={`container grid grid-cols-1 lg:grid-cols-3 gap-1 justify-center items-center ${isExpend === true ? "" : "hidden"}`}>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Customer Code</span>
                        </label>
                        <input ref={customerNoRef}  {...(setting?.Auto_Customer_Code ? { value: customersNo } : { defaultValue: '' })} type="text" placeholder="Type Customer Code Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Customer Name</span>
                        </label>
                        <input {...register("name")} type="text" placeholder="Type Customer Name Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Address</span>
                        </label>
                        <input {...register("address")} type="text" placeholder="Type Address Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Phone No</span>
                        </label>
                        <input {...register("phone")} type="text" placeholder="Type Phone No Here" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Opening Balance</span>
                        </label>
                        <input {...register("opening")} type="text" placeholder="Opening Balance" class="rounded-lg bg-red-200 p-4 text-black placeholder-black focus:border-blue-500 focus:bg-red-300 focus:outline-none w-full max-w-xs" required />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text">Image File</span>
                        </label>
                        <input type="file" id="customFile" placeholder="Image File" onChange={(e) => guardarArchivo(e)} />
                    </div>
                    <div class="form-control w-full max-w-xs">
                        <label class="label">
                            <span class="label-text text-sm">Status</span>
                        </label>
                        <select style={{ padding: '18px' }} {...register("status")} defaultValue='' class="rounded-lg bg-red-200 text-black placeholder-black w-full max-w-xs" required>
                            <option selected>Active</option>
                            <option>Inactive</option>
                        </select>
                    </div>
                    <input disabled={!userAc?.addCustomer} type="submit" value='Add Customer' className='btn w-full lg:w-60 bg-red-600 text-white mt-2 lg:mt-12' />
                </form>
            </div>
            <div className='text-xs mx-2 shadow-2xl p-2 lg:p-5'>
                <div className={`${isExpend === false ? "" : "hidden"}`}>
                    <div className='flex justify-between items-center my-4'>
                        <div class="form-control w-28  max-w-xs lg:mr-2">
                            <label class="label">
                                <span class="label-text">Show</span>
                            </label>
                            <select onChange={handleLimit} defaultValue='' class="text-center text-xs rounded-lg text-black select select-bordered select-error w-full max-w-xs" required>
                                <option selected >20</option>
                                <option>10</option>
                                <option>50</option>
                                <option>100</option>
                                <option>All</option>
                            </select>
                        </div>
                        <div class="form-control w-60 mt-9 ml-2 lg:ml-0">
                            <input onChange={handleSearchResult} type="text" placeholder="Search Customer" class="input text-xs input-bordered bg-white input-error w-full max-w-xs" />
                        </div>
                    </div>
                    {
                        items.length ? <div class="overflow-x-auto mt-8">
                            <table class="table w-full mx-auto">
                                <thead className='text-center'>
                                    <tr>
                                        <th className='bg-red-600 text-white normal-case'>SL</th>
                                        <th className='bg-red-600 text-white normal-case'>Image</th>
                                        <th className='bg-red-600 text-white normal-case'>Customer Code</th>
                                        <th className='bg-red-600 text-white normal-case'>Customer Name</th>
                                        <th className='bg-red-600 text-white normal-case'>Address</th>
                                        <th className='bg-red-600 text-white normal-case'>Phone</th>
                                        <th className='bg-red-600 text-white normal-case'>Opening</th>
                                        <th className='bg-red-600 text-white normal-case'>Status</th>
                                        <th className='bg-red-600 text-white normal-case'>Added By</th>
                                        <th className='bg-red-600 text-white normal-case'>Approved By</th>
                                        <th className='bg-red-600 text-white normal-case'>Edit</th>
                                        <th className='bg-red-600 text-white normal-case'>Delete</th>
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {
                                        items.map((customer, index) => <tr className='hover'>
                                            <td>{limit === "All" ? index + 1 : index + 1 + limit * (pageNo - 1)}</td>
                                            <td>
                                                <label className='cursor-pointer' for="image-modal" onClick={() => setSelectedImage(customer?.img ? customer?.img : person)}>
                                                    <img src={customer?.img ? customer?.img : person} alt="Customer Image" />
                                                </label>
                                            </td>
                                            <td>{customer.code}</td>
                                            <td>{customer.name}</td>
                                            <td>{customer.address}</td>
                                            <td>{customer.phone}</td>
                                            <td>{customer.opening}</td>
                                            <td> <button className={`btn btn-xs normal-case ${customer.status === 'Active' ? 'bg-green-700' : 'bg-red-700'}`}>{customer?.status}</button> </td>
                                            <td><button className='btn btn-xs btn-primary text-white normal-case'>{customer?.user ? customer.user : "User"}</button></td>
                                            {userAc.approvalCustomer ? <td>{customer?.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{customer?.approvedBy}</button> : <button onClick={() => handleApprove(customer)} className='btn btn-xs btn-secondary text-white normal-case'>Approve</button>}</td> :
                                                <td>{customer.approvedBy ? <button className='btn btn-xs btn-success text-white normal-case'>{customer.approvedBy}</button> : <button className='btn btn-xs btn-error text-white'>Not Approved</button>}</td>}
                                            {userAc?.editCustomer && <td><button><label for="update-modal" onClick={() => handleUpdateCustomer(customer)} className='text-xl text-green-700 cursor-pointer'><FaEdit /></label></button></td>}
                                            {userAc?.deleteCustomer && <td><button onClick={() => handleDeleteCustomer(customer._id)} className='text-xl text-red-700'><AiFillDelete /></button></td>}
                                        </tr>)
                                    }
                                </tbody>
                            </table>
                        </div> : ""
                    }
                    {limit === "All" ? "" : <div className='my-8 text-xs'>
                        <ReactPaginate
                            forcePage={pageNo - 1}
                            previousLabel={'Pre'}
                            nextLabel={'Next'}
                            breakLabel={'...'}
                            pageCount={pageCount}
                            marginPagesDisplayed={1}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={'btn-group justify-center'}
                            pageClassName={'btn btn-error text-white'}
                            activeClassName={'btn bg-white text-black'}
                            previousClassName={'btn btn-error text-white'}
                            nextClassName={'btn btn-error text-white'}
                            breakClassName={'btn btn-error text-white'}></ReactPaginate>
                    </div>}
                </div>
            </div>
            {
                updateCustomer && <UpdateModal refetchData={refetchData} pageNo={pageNo} setUpdateCustomer={setUpdateCustomer} updateCustomer={updateCustomer}></UpdateModal>
            }

            {selectedImage && (
                <ImageModal selectedImage={selectedImage} setSelectedImage={setSelectedImage} />
            )}

        </div>
    );
};

export default CustomerField;